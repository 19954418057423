export class ArrayUtils {}

declare global {
    interface Array<T> {
        toMap<K>(keyAccessor: (item: T) => K): Map<K, T>;

        whereNot<T>(...not: T[]): T[];

        toSet<T>(): Set<T>;
    }
}

Array.prototype.toMap = function <K, T>(keyAccessor: (item: T) => K) {
    const map = new Map<K, T>();
    this.forEach(item => map.set(keyAccessor(item), item));
    return map;
};

Array.prototype.whereNot = function <T>(...not: T[]): T[] {
    return this.filter(i => !not.includes(i));
};

Array.prototype.toSet = function <T>(): Set<T> {
    return new Set<T>(this);
};
