export declare class PromiseUtils {}

declare global {
    export interface PromiseConstructor {
        sync<T>(computation: () => T | Promise<T>): Promise<T>;

        isPromise(value: any): boolean;
    }
}

Promise.sync = <T>(computation: () => T | Promise<T>): Promise<T> => {
    try {
        const result = computation();
        if (Promise.isPromise(result)) {
            return result as Promise<T>;
        }

        return Promise.resolve(result);
    } catch (e) {
        return Promise.reject(e);
    }
};

Promise.isPromise = (value: any): boolean =>
    value && typeof value === "object" && typeof value.then === "function" && typeof value.catch === "function";
